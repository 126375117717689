import { useState, useRef } from "react";
import { addBreadcrumb } from "@web-monorepo/telemetry";
import { CameraVideoIcon } from "../../nessie/icons";
import { RAW_cssValue, ThemeUIStyleObject } from "../../nessie/stylingLib";
import { translate } from "../../utils/translate";
import { RealUploadType, UploadType } from "../../utils/uppy/uploadConfigs";
import { UnstyledButton } from "../buttons";
import ChevronButton from "../misc/ChevronButton";
import FileAttachment from "../misc/FileAttachment";
import T from "../misc/T";
import Video from "../misc/Video";
import PhotoModal from "../modals/Photo";
import useWatch from "../../hooks/useWatch";

export type Attachment = {
  type: UploadType;
  path: string;
  metadata?: { contentType?: string };
  contentType?: string;
  sources?: Array<{ path: string; contentType: string }>;
  transcoding?: boolean;
  thumbnailPath?: string;
};

type AttachmentsProps = {
  attachments: Attachment[];
  hideDownloadLinkOnVideo?: boolean;
  visible?: boolean;
  logEvent: (eventName: string) => void;
  canPlayWebm: boolean;
};

type AttachmentContentProps = Omit<AttachmentsProps, "attachments"> & {
  currentAttachment: Attachment;
  setShowingExpandedPhoto: (state: boolean) => void;
  videoStarted: React.MutableRefObject<boolean>;
};

const appendWidth = (path: string) => {
  if (path.indexOf("?") < 0) {
    return `${path}?w=500`;
  }
  return `${path}&w=500`;
};

const photoAttachment = ({ currentAttachment, logEvent, setShowingExpandedPhoto }: AttachmentContentProps) => {
  // Don't attempt to render images from the web-server
  const urlPath = /^https?:\/\//.test(currentAttachment.path)
    ? appendWidth(currentAttachment.path)
    : currentAttachment.path;

  return (
    <UnstyledButton
      data-name="storyPostImage"
      sx={{
        backgroundImage: `url("${urlPath}")`,
        backgroundSize: "cover",
        backgroundPosition: "50% 50%",
        backgroundRepeat: "no-repeat",
        boxShadow: RAW_cssValue("inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"),
        ...MEDIA_STYLE,
      }}
      aria-label={translate({ str: "dojo.common:actions.open_image", fallback: "Open image" })}
      onClick={() => {
        logEvent("class_story.post_photo.open");
        setShowingExpandedPhoto(true);
      }}
    />
  );
};

const videoAttachment = ({
  currentAttachment,
  logEvent,
  videoStarted,
  canPlayWebm,
  hideDownloadLinkOnVideo,
  visible,
}: AttachmentContentProps) => {
  const sources = transformVideoSources(currentAttachment);

  const onVideoPlay = () => {
    if (!videoStarted.current) {
      logEvent("class_story.post_video.start");
      videoStarted.current = true;
    } else {
      logEvent("class_story.post_video.resume");
    }
  };

  const onVideoPause = () => {
    logEvent("class_story.post_video.pause");
  };

  return (
    <div sx={MEDIA_STYLE}>
      {!canPlayWebm && !!currentAttachment.transcoding && (
        <div
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "dt_s",
            backgroundColor: RAW_cssValue("#4B4FA1"),
            position: "absolute",
            zIndex: "100",
            width: "100%",
          }}
        >
          <div sx={{ position: "relative" }}>
            <T
              sx={VIDEO_TRANSCODING_TITLE}
              str="dojo.common:class_story.video_transcoding_title"
              fallback="Preparing video"
            />
            <T
              sx={VIDEO_TRANSCODING_SUBTITLE}
              str="dojo.common:class_story.video_transcoding_subtitle"
              fallback="This usually takes a few minutes"
            />
          </div>
          <CameraVideoIcon color="dt_content_light" />
        </div>
      )}
      <Video
        controlsList={hideDownloadLinkOnVideo ? "nodownload" : ""}
        sources={sources}
        poster={currentAttachment.thumbnailPath}
        onPlay={onVideoPlay}
        onPause={onVideoPause}
        preload={visible ? "auto" : "none"}
        onError={(event) => {
          const error = (event && event.target && "error" in event.target && event.target.error) || undefined;
          addBreadcrumb({
            type: "",
            level: "error",
            event_id: "story.video.error",
            category: "story.video",
            message: "Error loading video attachment on story post",
            data: {
              currentAttachment,
              metadata: currentAttachment.metadata,
              error: error ?? "unknown",
              online: String(typeof window !== "undefined" ? (window?.navigator?.onLine ?? "unknown") : "unknown"),
            },
          });
        }}
      />
    </div>
  );
};

const fileAttachment = ({ currentAttachment }: AttachmentContentProps) => {
  return <FileAttachment {...currentAttachment.metadata} path={currentAttachment.path} />;
};

const attachmentContentFor: Partial<Record<RealUploadType, (data: AttachmentContentProps) => React.ReactNode>> = {
  photo: photoAttachment,
  video: videoAttachment,
  file: fileAttachment,
};

const Attachments = ({ attachments, canPlayWebm, hideDownloadLinkOnVideo, visible, logEvent }: AttachmentsProps) => {
  const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState(0);
  const [showingExpandedPhoto, setShowingExpandedPhoto] = useState(false);
  const videoStarted = useRef(false);

  useWatch([attachments.length], () => {
    // if attachment length changes, reset index to zero to ensure we have a valid index
    setCurrentAttachmentIndex(0);
  });

  if (!attachments.length) {
    return null;
  }
  const currentAttachment = attachments[currentAttachmentIndex];

  const currentAttachmentType = currentAttachment && currentAttachment.type;

  const attachmentArgs = {
    currentAttachment,
    logEvent,
    setShowingExpandedPhoto,
    canPlayWebm,
    hideDownloadLinkOnVideo,
    visible,
    videoStarted,
  };
  const attachmentContent =
    (currentAttachmentType && attachmentContentFor[currentAttachmentType]?.(attachmentArgs)) || undefined;

  return (
    <div sx={{ position: "relative" }}>
      {attachmentContent}
      {attachments[currentAttachmentIndex - 1] ? (
        <ChevronButton
          direction="left"
          sx={{
            position: "absolute",
            top: "50%",
            left: "5%",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
          aria-label={translate({ str: "dojo.common:actions.previous", fallback: "Previous" })}
          onClick={() => setCurrentAttachmentIndex(currentAttachmentIndex - 1)}
          data-name="post_contents_text_and_attachment:previous"
        />
      ) : null}
      {attachments[currentAttachmentIndex + 1] ? (
        <ChevronButton
          direction="right"
          sx={{
            position: "absolute",
            top: "50%",
            right: "5%",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
          aria-label={translate({ str: "dojo.common:actions.next", fallback: "Next" })}
          onClick={() => setCurrentAttachmentIndex(currentAttachmentIndex + 1)}
          data-name="post_contents_text_and_attachment:next"
        />
      ) : null}
      {attachments.length > 1 ? (
        <div
          sx={{
            color: "dt_content_light",
            background: "rgba(0, 0, 0, 0.5)",
            position: "absolute",
            top: 18,
            right: 18,
            borderRadius: "dt_radius_s",
            paddingY: "dt_xs",
            paddingX: "dt_s",
          }}
        >
          <T str="dojo.common:in_list" subs={{ index: currentAttachmentIndex + 1, total: attachments.length }} />
        </div>
      ) : null}
      {currentAttachment && showingExpandedPhoto && (
        <MediaAttachmentModal currentAttachment={currentAttachment} onClose={() => setShowingExpandedPhoto(false)} />
      )}
    </div>
  );
};

export default Attachments;

function transformVideoSources(videoAttachment: Attachment) {
  const firstSource = {
    path: videoAttachment.path,
    contentType:
      videoAttachment.contentType || (videoAttachment.metadata && videoAttachment.metadata.contentType) || "video/mp4",
  };
  const sources = videoAttachment.sources ? [firstSource, ...videoAttachment.sources] : [firstSource];
  return sources;
}

// eslint-disable-next-line react-refresh/only-export-components
const MediaAttachmentModal = ({
  currentAttachment,
  onClose,
}: {
  currentAttachment: Attachment;
  onClose: () => void;
}) => {
  const { type } = currentAttachment;
  if (type === "photo" || type === "video") {
    return (
      <PhotoModal
        data-name="post_contents_text_and_attachment:media_attachment_modal:photo_modal"
        src={type === "photo" ? currentAttachment.path : undefined}
        sources={type === "video" ? transformVideoSources(currentAttachment) : undefined}
        poster={type === "video" ? currentAttachment.thumbnailPath : undefined}
        close={onClose}
      />
    );
  } else {
    return null;
  }
};

const MEDIA_STYLE = {
  width: "100%",
  height: "50rem",
  margin: RAW_cssValue("0 -1px"),
  cursor: "pointer",
  position: "relative" as const,
};

const VIDEO_TRANSCODING_TITLE: ThemeUIStyleObject = {
  color: "dt_content_light",
  fontStyle: "normal",
  fontWeight: 800,
  fontSize: "16px",
  lineHeight: "18px",
  display: "block",
};
const VIDEO_TRANSCODING_SUBTITLE: ThemeUIStyleObject = {
  color: "dt_content_light",
  fontStyle: "normal",
  fontSize: "16px",
  lineHeight: "18px",
};
