import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { translate } from "../../utils/translate";
import loadingMojoGif from "../../assets/images/components/loading_mojo/loading-mojo.gif";
import mojoDanceGif from "../../assets/images/components/loading_mojo/mojo-dance-88.gif";

export type LoadingMojoProps = {
  inline?: boolean;
  block?: boolean;
  border?: boolean;
  noOverlay?: boolean;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
  text?: string;
  className?: string;
  danceGif?: boolean;
};

const LoadingMojo = ({
  inline,
  block,
  border,
  "data-name": dataName = "loadingMojo",
  text,
  noOverlay,
  className,
  danceGif,
}: LoadingMojoProps) => {
  let style: ThemeUIStyleObject = {};

  if (inline) {
    style = { ...style, ...styles.inline };
  } else if (block) {
    style = { ...style, ...styles.block };
  } else {
    style = { ...style, ...styles.full };
  }

  if (border) {
    style = { ...style, ...styles.border };
  }

  if (danceGif) {
    style = { ...style, ...styles.danceMojo };
  }

  const mojo = (
    <>
      <div sx={{ position: "absolute", color: "transparent" }}>
        {translate({ str: "dojo.common:content_loading", fallback: "Content is loading" })}
      </div>
      <img
        alt={translate({ str: "dojo.common:content_loading", fallback: "Content is loading" })}
        sx={style}
        src={danceGif ? mojoDanceGif : loadingMojoGif}
        data-name={dataName}
      />
    </>
  );

  if (inline) {
    // Added this to backward compatibility. The inline mojo without text
    // is used in a number of places and I don't want to break that.
    if (text) {
      return (
        <div sx={style} className={className}>
          <div sx={{ justifyContent: "flex-start", flexDirection: "row", display: "flex", flexWrap: "nowrap" }}>
            <div
              sx={{ flex: "0 0 auto", marginRight: "dt_s", alignSelf: "center" }}
              role="status"
              aria-live="assertive"
            >
              <img
                alt={translate({ str: "dojo.common:content_loading", fallback: "Content is loading" })}
                src={loadingMojoGif}
                data-name={dataName}
              />
              <div sx={{ position: "absolute", color: "transparent" }}>
                {translate({ str: "dojo.common:content_loading", fallback: "Content is loading" })}
              </div>
            </div>
            <div sx={{ flex: "1 0 0px", alignSelf: "center" }}>
              <span>{text}</span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div sx={{ display: "inline" }} role="alert" aria-live="assertive" className={className}>
          {mojo}
        </div>
      );
    }
  } else if (block) {
    return (
      <div role="status" aria-live="assertive" className={className}>
        {mojo}
      </div>
    );
  } else {
    return (
      <div role="status" aria-live="assertive" className={className}>
        {!noOverlay && <div sx={styles.greyout} />}
        {mojo}
      </div>
    );
  }
};

export default LoadingMojo;

const styles: Record<string, ThemeUIStyleObject> = {
  full: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
    zIndex: 1000,
  },
  border: {
    boxShadow: "dt_shadow_shadezies_small",
    borderRadius: "dt_radius_s",
    padding: "dt_l",
    backgroundColor: "dt_background_primary",
  },
  inline: {
    display: "inline-block",
  },
  block: {
    display: "block",
    marginBottom: "dt_s",
  },
  greyout: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "dt_background_primary",
    opacity: "0.4",
    zIndex: 1000,
  },
  danceMojo: {
    height: "150px",
    width: "150px",
  },
};
